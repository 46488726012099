body {
  font-family: Montserrat, sans-serif;
  color: #2a2a2a;
}

.hero-text {
  width: 1544px;
  height: 210px;
  background-image: linear-gradient(
    to right,
    #fff,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  bottom: 20%;
  left: 0;
}

.customBlue {
  color: #0e126c;
}

.sunWest {
  color: #fd9618;
}

.babyBlue {
  color: #009cdc;
}

.navbar-brand {
  display: none;
  position: absolute;
  top: 5vh;
  left: 8vw;
}

.bg-light {
  background-color: transparent !important;
}

ul {
  list-style: none;
  padding-inline-start: 15px;
}

ul li {
  padding-bottom: 3px;
}

.productsList > li:before {
  font-weight: 700;
  content: "\2022";
  color: #f88002;
  display: inline-block;
  width: 0.7em;
  margin-left: -1em;
}

footer {
  font-size: 12px;
}

#footer {
  position: relative;
  bottom: -8vh;
  right: 1vw;
  display: flex;
  justify-content: flex-end;
  text-align: justify;
}

.btn-primary {
  color: #fff !important;
  background-image: linear-gradient(to left, #083f88, #0095c8 51%, #083f88);
  border-radius: 6px;
  box-shadow: 1px 1px 8px #d2d2d2;
  margin-bottom: 1rem;
}

@media (max-width: 991px) {
  #footer {
    padding: 0.2rem 2rem;
  }
}

@media (max-width: 768px) {
  body {
    background-image: none;
  }
  .navbar-brand {
    display: block;
  }
}

@media (max-width: 499px) {
  .navbar img {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .globalApplyModalWrapper .form-control {
    font-size: 14px;
  }
}

.globalApplyModal {
  z-index: 9999 !important;
}

.globalApplyModal > div > div > div > div {
  font-size: 21px !important;
}

.globalApplyModal > div > div {
  padding: 10px 5px;
  overflow-x: auto;
  background-repeat: no-repeat;
  border-radius: 15px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}

.globalApplyModal > div > div > div {
  border-bottom: none !important;
}

.globalApplyModal label {
  color: #333;
}

.globalApplyModal .invalid-feedback {
  color: #dc3545;
}

.globalApplyModal .modal-header {
  padding: 0 20px;
}

.apply_btn_color {
  color: white !important;
}
